:root {
  --brandColor: #284736 ;
  --brandSecColor: #284736 ;
}

$brandColor: var(--brandColor);
$brandSecColor: var(--brandSecColor);
$borderColor: #CCCCCC;
$yellowColor: #FFA800;
$textColor: #282828;

$badgeColor: #FF3939;
$confirmButtonColor: #2151A1;
$blueButtonColor: #0073F2;
$blueColor: #1260b7;

$inputBorderColor: #868686;
$inputTextColor: #1D1C1D;

$borderGreyColor: #e5e5e5;
$darkBlueColor: #10233C;
