@import "../mainColors";

.front-layout-wrapper {
  height: 100vh;
  width: 100%;
  color: white;
  position: relative;
  overflow: hidden;

  .info-content {
    width: 100%;
    height: 100vh;
    letter-spacing: 0.5px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;

    .bgImg {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .responsiveBeer {
      display: none;
    }

    .responsiveImage {
      display: none;
    }

    .beer {
      position: absolute;
      top: 0;
      right: 0;
      height: calc(100vh);
      //width: 44%;
      object-fit: contain;
      object-position: right top;
      z-index: 0;
    }

    .header {
      z-index: 1000;
      width: 38%;

      .page-title {
        font-size: 36px;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.02em;
        text-align: left;
        color: white;
        width: 440px;
        @media screen and (max-width: 500px){
          font-size: 24px;
          width: 330px;
        }
      }

      .page-description {
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0;
        text-align: left;

        span {
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          letter-spacing: 0;
          text-align: left;
          color: $yellowColor;
        }
      }
    }

    .form-wrapper {
      width: 38%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      position: relative;
      z-index: 1;

      .row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .input-group {
          width: calc(96% / 2);
          margin-top: 24px;

          input, textarea, select {
            height: 44px;
            padding: 10px 14px;

            &::placeholder {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0;
              text-align: left;
              color: #667085;
            }
          }
        }

      }

      .send-button {
        height: 44px;
        width: 100%;
        border-radius: 8px;
        margin-top: 24px;
        background-color: $yellowColor;
        position: relative;
        color: #162D23;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;


        .title {
          margin-top: 1px;
          margin-right: 6px;
        }

        .tick-icon, i {
          margin-left: 8px;
        }

        .tick-icon {
          width: 0;
          height: 14px;
          transition: all 0.2s;
          visibility: visible;

          path {
            fill: white;
          }
        }

        .show-tick {
          width: 14px;
          visibility: visible;
          opacity: 1;
          margin-left: 10px;
        }

        .hide-tick {
          width: 0;
          visibility: hidden;
          opacity: 0;
        }
      }

      .checkbox {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 24px;
        cursor: pointer;

        input {
          width: 16px;
          height: 16px;
          margin-bottom: 8px;
          cursor: pointer;

          @media screen and (max-width: 1100px){
            width: unset;
            height: unset;
          }
        }

        label {
          width: 80%;
          margin-left: 8px;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: white;
          cursor: pointer;
        }

        &.checked-error {
          label {
            color: red
          }
        }
      }

      .input-group {
        width: 100%;
        margin-top: 24px;

        input {
          height: 44px;
          border-radius: 8px;
          font-size: 15px;
          padding: 10px 14px;

          &::placeholder {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0;
            text-align: left;
            color: #667085;
          }
        }

        label {
          color: white;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0;
          text-align: left;
          margin-bottom: 8px;
        }

        input, textarea, select {
          border: 2px solid transparent;
        }
      }

      .tell-input {
        position: relative;

        label {
          .code {
            position: absolute;
            bottom: 10px;
            left: 12px;
            margin-bottom: 0;
            color: #667085;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;

          }
        }

        input {
          padding-left: 60px;
        }
      }

      .sms-block {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-top: 24px;

        .sms-block-number {
          font-size: 18px;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: 0;
          text-align: left;
          color: white;
          display: flex;

          p {
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: 0;
            text-align: left;
            color: $yellowColor;
            margin: 0 10px;
          }
        }

        .sms-block-price {
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0;
          text-align: left;
          color: #8B919A;
        }
      }
    }
  }
}

@media all and (max-width: 900px) {

  .front-layout-wrapper {
    height: unset;
    position: unset;
    overflow: hidden;

    .info-content {
      height: 100%;
      position: relative;
      //background: radial-gradient(circle, rgba(40, 71, 54, 1) 16%, rgba(22, 45, 35, 1) 100%);
      background: #084c35;
      padding-bottom: 50px;

      .header {
        width: 100%;
        padding: 40px 20px 0 20px;
      }

      .form-wrapper {
        width: 100%;
        padding: 0 20px;
      }

      .bgImg {
        display: none;
      }

      .responsiveBeer {
        display: flex;
        width: 100%;
      }

      .responsiveImage {
        //display: flex;
        //width: 100%;
        //margin-top: 46px;
      }
    }
  }

}

@media screen and (max-width: 400px) {
  .front-layout-wrapper {
    .info-content {
      .header {
        padding: 30px 10px 0 20px;
        .page-title{
          width: 100%;
          line-height: 26px;
          font-size: 24px;
        }
        .page-description{
          width: 100%;
          line-height: 16px;
          font-size: 14px;
        }
      }
      .form-wrapper {
        .row {
          flex-direction: column;

          .input-group {
            width: 100%;
          }
        }
      }
    }
  }
}



