@font-face {
  font-family: Bold;
  src: url(../fonts/SF-Pro-Text-Bold.otf);
}
@font-face {
  font-family: Medium;
  src: url(../fonts/SF-Pro-Text-Medium.otf);
}
@font-face {
  font-family: Regular;
  src: url(../fonts/SF-Pro-Text-Regular.otf);
}