
.success-message-modal-content {
  width: 30%;
  background-color: white;
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.65);

  .modal-header-block{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .state-icon{
      svg{
        width: 48px;
        height: 48px;
      }
    }
    .modal-close-btn{
      background-color: white;
    }
  }

  .close-button{
    width: 100%;
    height: 44px;
    margin-top: 32px;
    background-color:#FFA800;
    border: 1px solid #213E30;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #162D23;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    border-radius: 8px;
  }

  .modal-title{
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0;
    text-align: left;
    color: #101828;
    margin-top: 16px;
  }
  .modal-text {
    color: #475467;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    margin-top: 4px;


    .prize-name {
      margin: 0 5px;
    }
  }

  .hot-line {
    font-size: 14px;
    margin-top: 10px;
    color: #8e8e90;
  }

}


@media all and (max-width: 1400px) {

  .success-message-modal-content {
    width: 36%;
  }
}


@media all and (max-width: 1000px) {

  .success-message-modal-content {
    width: 40%;
    padding: 30px;

    .modal-logo {
      width: 150px;
      height: 50px;
      margin-bottom: 20px;
    }

  }
}


@media all and (max-width: 750px) {

  .success-message-modal-content {
    width: 46%;
  }
}


@media all and (max-width: 600px) {

  .success-message-modal-content {
    width: 90%;
    padding: 20px;

    .modal-logo {
      width: 120px;
      height: 40px;
      margin-bottom: 20px;
    }
  }

}